var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-main',[_c('BackOnTop',{attrs:{"isCarrier":true,"showDelete":""},on:{"click:delete":_vm.handleDeteleLb}}),_c('PageTitleBar',{attrs:{"title":"編輯寶貝資料"}}),_c('v-container',{staticClass:"register-container px-4 mb-4"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12"}},[_c('FancyInput',{attrs:{"label":"寶貝暱稱","iconName":"ic24Pname.svg","rules":[() => !!_vm.form.alias || '請輸入寶貝暱稱'],"isError":_vm.handleBlur(true, _vm.form.alias),"hide-details":"true","wrongHint":"請輸入寶貝暱稱","isChecking":_vm.isChecking,"inputEl":"petAlias","required":""},model:{value:(_vm.form.alias),callback:function ($$v) {_vm.$set(_vm.form, "alias", $$v)},expression:"form.alias"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12"}},[_c('FancySelect',{attrs:{"label":"寶貝種類","iconName":"ic24Breed.svg","rules":[() => !!_vm.form.type || '請輸入寶貝種類'],"items":_vm.petTypeList,"options":_vm.petTypeList,"isError":_vm.handleBlur(true, _vm.form.type),"hide-details":"true","wrongHint":"請輸入寶貝種類","isChecking":_vm.isChecking,"inputEl":"petType","required":""},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12"}},[_c('FancySelect',{attrs:{"label":"寶貝品種","iconName":"ic24Breed.svg","rules":[() => !!_vm.form.breed || '請輸入寶貝品種'],"items":_vm.petBreedList.filter(function (obj) {
                                if (obj.type == _vm.form.type) return true;
                                else return false;
                            }),"options":_vm.petBreedList.filter(function (obj) {
                                if (obj.type == _vm.form.type) return true;
                                else return false;
                            }),"isError":_vm.handleBlur(true, _vm.form.Breed),"hide-details":"true","wrongHint":"請輸入寶貝品種","isChecking":_vm.isChecking,"inputEl":"petBreed","required":""},model:{value:(_vm.form.breed),callback:function ($$v) {_vm.$set(_vm.form, "breed", $$v)},expression:"form.breed"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","sm":"6"}},[_c('FancySelect',{attrs:{"label":"寶貝生日 年","iconName":"ic24Birthday.svg","options":_vm.yearOptions,"items":_vm.yearOptions,"hide-details":"true","hideCheck":""},model:{value:(_vm.form.birthday_year),callback:function ($$v) {_vm.$set(_vm.form, "birthday_year", $$v)},expression:"form.birthday_year"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"3","sm":"3"}},[_c('FancySelect',{attrs:{"label":"月","hideCheck":"","hide-details":"true","items":[
                            {
                                text: '月',
                                value: '',
                                selected: true,
                                disabled: true,
                            },
                            { text: '01', value: '01' },
                            { text: '02', value: '02' },
                            { text: '03', value: '03' },
                            { text: '04', value: '04' },
                            { text: '05', value: '05' },
                            { text: '06', value: '06' },
                            { text: '07', value: '07' },
                            { text: '08', value: '08' },
                            { text: '09', value: '09' },
                            { text: '10', value: '10' },
                            { text: '11', value: '11' },
                            { text: '12', value: '12' },
                        ],"options":[
                            {
                                text: '月',
                                value: '',
                                selected: true,
                                disabled: true,
                            },
                            { text: '01', value: '01' },
                            { text: '02', value: '02' },
                            { text: '03', value: '03' },
                            { text: '04', value: '04' },
                            { text: '05', value: '05' },
                            { text: '06', value: '06' },
                            { text: '07', value: '07' },
                            { text: '08', value: '08' },
                            { text: '09', value: '09' },
                            { text: '10', value: '10' },
                            { text: '11', value: '11' },
                            { text: '12', value: '12' },
                        ]},model:{value:(_vm.form.birthday_month),callback:function ($$v) {_vm.$set(_vm.form, "birthday_month", $$v)},expression:"form.birthday_month"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"3","sm":"3"}},[_c('FancySelect',{attrs:{"label":"日","hideCheck":"","hide-details":"true","items":_vm.dayOptions,"options":_vm.dayOptions},model:{value:(_vm.form.birthday_day),callback:function ($$v) {_vm.$set(_vm.form, "birthday_day", $$v)},expression:"form.birthday_day"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12"}},[_c('FancySelect',{attrs:{"label":"寶貝性別","iconName":"ic24PetGender.svg","rules":[() => !!_vm.form.gender || '請輸入寶貝性別'],"items":[
                            { text: '公', value: '01' },
                            { text: '母', value: '02' },
                            { text: '其他', value: '03' },
                        ],"options":[
                            { text: '公', value: '01' },
                            { text: '母', value: '02' },
                            { text: '其他', value: '03' },
                        ],"isError":_vm.handleBlur(true, _vm.form.gender),"hide-details":"true","wrongHint":"請輸入寶貝性別","isChecking":_vm.isChecking,"inputEl":"petGender","required":""},model:{value:(_vm.form.gender),callback:function ($$v) {_vm.$set(_vm.form, "gender", $$v)},expression:"form.gender"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12"}},[_c('FancySelect',{attrs:{"label":"寶貝體型","iconName":"ic24PetBody.svg","rules":[() => !!_vm.form.body || '請輸入寶貝體型'],"items":[
                            { text: '小型犬', value: '2' },
                            { text: '中型犬', value: '4' },
                            { text: '大型犬', value: '6' },
                            { text: '幼貓', value: '7' },
                            { text: '成貓', value: '8' },
                        ],"options":[
                            { text: '小型犬', value: '2' },
                            { text: '中型犬', value: '4' },
                            { text: '大型犬', value: '6' },
                            { text: '幼貓', value: '7' },
                            { text: '成貓', value: '8' },
                        ],"isError":_vm.handleBlur(true, _vm.form.body),"hide-details":"true","wrongHint":"請輸入寶貝體型","isChecking":_vm.isChecking,"inputEl":"petBody","required":""},model:{value:(_vm.form.body),callback:function ($$v) {_vm.$set(_vm.form, "body", $$v)},expression:"form.body"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12"}},[_c('FancyInput',{attrs:{"label":"毛色","iconName":"ic24Comb.svg","rules":[() => !!_vm.form.comb || '請輸入寶貝毛色'],"isError":_vm.handleBlur(true, _vm.form.comb),"hide-details":"true","wrongHint":"請輸入寶貝毛色","isChecking":_vm.isChecking,"inputEl":"petComb","required":""},model:{value:(_vm.form.comb),callback:function ($$v) {_vm.$set(_vm.form, "comb", $$v)},expression:"form.comb"}})],1),_c('v-col',{staticClass:"py-0 mb-4",attrs:{"cols":"12","sm":"12"}},[_c('FancyRadio',{attrs:{"row":"","items":[
                            { label: '已結紮', value: '1' },
                            { label: '未結紮', value: '0' },
                        ]},model:{value:(_vm.form.isNeutered),callback:function ($$v) {_vm.$set(_vm.form, "isNeutered", $$v)},expression:"form.isNeutered"}})],1)],1),_c('FancyButton',{staticClass:"mb-6",attrs:{"label":"儲存修改","width":"150","height":"48"},on:{"click":_vm.saveEdit}})],1)],1),_c('FancyDialog',{model:{value:(_vm.isDeleteOpened),callback:function ($$v) {_vm.isDeleteOpened=$$v},expression:"isDeleteOpened"}},[_c('v-container',{staticClass:"dialog_pet_delete",attrs:{"fluid":""}},[_c('v-form',{ref:"delete_reaspn_form",model:{value:(_vm.deleteValid),callback:function ($$v) {_vm.deleteValid=$$v},expression:"deleteValid"}},[_c('p',{staticClass:"title mb-3"},[_vm._v("確定要刪除寶貝資料?")]),_c('p',{staticClass:"desc mb-6"},[_vm._v(" 親愛的會員，您是否要刪除寶貝「"+_vm._s(_vm.form.alias)+"」的資料 ")]),_c('div',[_c('p',{staticClass:"mb-2"},[_vm._v("移除原因")]),_c('FancyRadio',{staticClass:"radio_group f_sm",attrs:{"row":"","flexDirection":"column","items":[
                            {
                                label: '寶貝已成為小天使',
                                value: '寶貝已成為小天使',
                            },
                            {
                                label: '寶貝已轉交由他人照顧',
                                value: '寶貝已轉交由他人照顧',
                            },
                            { label: '其他', value: '其他' },
                        ],"rules":[
                            () => !!_vm.deletePetReason || '請選擇移除原因',
                        ],"required":""},model:{value:(_vm.deletePetReason),callback:function ($$v) {_vm.deletePetReason=$$v},expression:"deletePetReason"}}),(_vm.deletePetReason == '其他')?_c('FancyTextarea',{staticClass:"textarea_else",attrs:{"height":"100","placeholder":"其他原因"},model:{value:(_vm.deletePetReasonElse),callback:function ($$v) {_vm.deletePetReasonElse=$$v},expression:"deletePetReasonElse"}}):_vm._e()],1),_c('div',{staticClass:"footer d-flex justify-center"},[_c('FancyButton',{staticClass:"btn mr-3",attrs:{"isGreen":"","label":"取消"},on:{"click":function($event){_vm.isDeleteOpened = false}}}),_c('FancyButton',{staticClass:"btn",attrs:{"color":"primary","label":"確認"},on:{"click":_vm.deletePet}})],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }